frappe.ui.form.MultiOptionsDialog = class MultiOptionsDialog {
	constructor(opts) {
		Object.assign(this, opts)
		this.init();
	}

	init() {
		this.get_content_from_options();
		this.make();
	}

	get_content_from_options() {
		const options = this.options.map(option => {
			const button = document.createElement("div")
			button.classList.add("multioptions-select-button");
			const innerButton = document.createElement("button");
			innerButton.classList.add("btn", "d-flex", "flex-column", "justify-content-center", "align-items-center", "h-100");
			if (option.primary) {
				innerButton.classList.add("btn-primary")
			}

			if (option.secondary) {
				innerButton.classList.add("btn-secondary")
			}

			if (option.classes) {
				innerButton.classList.add(option.classes)
			}
			
			if (option.icon) {
				const icon = document.createElement("div");
				icon.innerHTML = frappe.utils.icon(option.icon, "md")
				innerButton.appendChild(icon)
			}

			const label = document.createElement("div")
			label.textContent = option.label || ''

			innerButton.appendChild(label)
			button.appendChild(innerButton)

			button.addEventListener("click", option.action)

			return button
		})

		const optionsCount = Math.min(this.options.length, 6)

		const container = document.createElement("div")
		container.classList.add("container", "multioptions-select");
		const innerContainer = document.createElement("div")
		innerContainer.classList.add("d-flex", "flex-column", "justify-content-center", "align-items-center", "multioptions-select-container");
		const titleDiv = document.createElement("div")
		titleDiv.classList.add("title");
		titleDiv.textContent = this.title || ''
		const optionsDiv = document.createElement("div")
		optionsDiv.classList.add("multioptions-select-options");
		optionsDiv.style.gridTemplateColumns = `repeat(${optionsCount}, 1fr)`;

		options.forEach(o => optionsDiv.appendChild(o))

		innerContainer.appendChild(titleDiv)
		innerContainer.appendChild(optionsDiv)
		container.appendChild(innerContainer)


		this.content = container
	}

	make() {
		this.dialog = new frappe.ui.Dialog({
			title: this.dialog_title || __("Select an option"),
			fields: [
				{
					"fieldtype": "HTML",
					"fieldname": "content"
				}
			],
			size: this.size,
			onhide: () => {
				this.dialog.is_visible = false;
			}
		});

		this.dialog.get_field("content").wrapper.appendChild(this.content)
		this.dialog.show();
	}

};
